import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import './index.css';
import { StaticImage } from 'gatsby-plugin-image';

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="IndexContent">
      <div className="HomeText">
        <h1 className="BlackText">Heather & Nick</h1>
        <div>
          <p className="YellowText">
            Saturday 23rd September 2023 3pm
            <br />
            The White Hart Inn, Moorwood Moor
          </p>
        </div>
        <hr />
        <div className="HomeText">
          <h2>We did it!</h2>
          <p>
            Together with you, our closest friends and family, we celebrated our
            10th anniversary with our dream wedding. We hope you enjoyed
            celebrating with us. That the arcade games, axe throwing, and bouncy
            castle brought you lots of laughs and great memories.
          </p>
          <p>
            Please be sure to share any photos you've taken in the shared
            gallery, which you can find <Link to="/photos">here</Link>. Once
            received we will be uploading the media from the photographer and
            videographer and photobooth to the gallery for all to see.
          </p>
          <p>
            If you didn't take a newspaper favour and would like one as a
            keepsake, let us know and we'll post one to you.
          </p>
          <p>
            Enjoy revisiting the memories of the day, thanks again for
            celebrating with us, lots of love,
            <br />
            Heather and Nick xXx
          </p>
          <StaticImage
            className="profileImage"
            src="../assets/images/attempt2.jpg"
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
